/***************************/
/*******Common to All*******/
/***************************/
.table-drag-select {
    table-layout: fixed;
    user-select: none;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
  }

.table-drag-select td {
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    border: 0.1rem solid transparent;
    border-radius: 1px;
    background-clip: padding-box;
    padding-left: 0rem;
    padding-right: 0rem;
    font-size: 1rem;
  }

.table-drag-select td.cell-enabled {
    background-color: #e3f2fd;
    color: black;
    cursor: cell;
  }

  .table-drag-select td.cell-disabled {
  }

  .table-drag-select td.cell-selected {
    background-color: #1565c0;
    color: white;
  }

  .table-drag-select td.cell-being-selected {
    background-color: #3F83D5;
    color: white;
  }

.viewer-table {
    width: 100%;  
    table-layout: fixed;
    user-select: none;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
  }
  .viewer-table td {
    text-align: center;
    border-radius: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    border: 0.1rem solid transparent;
    background-clip: padding-box;
    line-height: 1rem;
    padding-left: 0rem;
    padding-right: 0rem;
    font-size: .6rem;
  }

  .viewer-table td.cell-enabled {
    background-color: #DDD;
    color: black;
    cursor: cell;
  }

  .viewer-table td.cell-enabled-gray {
    background-color: #a9a9a9;
    color: white;
    cursor: cell;
  }

  .viewer-table td.cell-selected {
    background-color: #00796b;
  }
  
  .viewer-table td.cell-being-selected {
    background-color: #3F83D5;
  }

/***************************/
/*******Very Small Screens*******/
/***************************/

@media only screen and (max-width: 400px) {
  .table-drag-select {
    width: 100%;  
  }

  .viewer-table {
    table-layout: fixed;
  }

  .table-drag-select td {
    line-height: 2rem;
  }
}

/***************************/
/*******Small Screens*******/
/***************************/

@media only screen and (min-width: 400px) {
  .table-drag-select {
    width: 100%;  
  }

  .viewer-table {
    table-layout: fixed;
  }

  .table-drag-select td {
    line-height: 2rem;
  }
}


/****************************/
/*******Large Screens*******/
/****************************/

@media only screen and (min-width: 700px) {
  .table-drag-select {
    width: 80%;
  }

  .viewer-table {
    table-layout: auto;  
  }

  .table-drag-select td {
    line-height: 3rem;
  }
}



