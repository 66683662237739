body.dark-mode {
  background-color: #222222;
  color: #fff;
}

body.light-mode {
  background-color: #eceff1;
  color: #333;
  transition: background-color 0.3s ease;
}

footer {
  position: relative;
  left: 0;
  padding: 10px;
  bottom: 0;
  width: 100%;
  color: inherit;
}


.main {
    justify-content: center;
    display: flex;
    margin-top: 5vh;
}